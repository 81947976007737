/* @value white, navy, lightBlue, background from "../values/colors.css" */

:root {
    --white: #FFFFFF;
    --navy: #22577C;
    --lightBlue: #C7DDED;
    --background: #E5E5E5;
    --yellow: #f9c81999;
}
.nav{
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: auto;
    padding-top: 1%;
    padding-bottom: 1%;

    background-color: var(--background);

}

.navTitle {
    display:flex;
    justify-content: center;
    align-items: center;

    width: 250px;

    font-weight: 800;
    font-size: 25px;

    background: transparent;
    border-style: none;
}

.navTitle:hover{
    cursor: pointer;
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100px;

    font-weight: 800;
    font-size: 25px;

    border-style: none;
    background-color: transparent;
}

.button:hover{
    color: var(--navy);
    cursor: pointer;
}

.aboutButton {
    display: flex;

    width: 100px;

    margin-right: 23px;

}
.eventButton {
    display: flex;

    width: 100px;

    margin-right: 15px;
}
.joinButton {
    display: flex;

    width: 100px;

    margin-right: 20px;
}