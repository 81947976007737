:root {
    --white: #FFFFFF;
    --navy: #22577C;
    --lightBlue: #C7DDED;
    --background: #E5E5E5;
    --yellow: #f9c81999;
}
.headerContainer{
    /* margin-left: 10%; */
    width: auto;
    height: auto;
}
.header{
    margin-left: 10%;

    font-weight: 800;

    width: auto;
    height: auto;

    height: auto;

    background: linear-gradient(180deg,rgba(255,255,255,0) 70%, var(--yellow) 50%);

}
.headerWave{
    position: absolute;

    /* padding-bottom: 50px; */

}
.registerButton{
    /* background color/border color will depend on whether registration is open or not */
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 45%;
    min-height: 30px;
    height: 40px;

    color: white;

    text-align: center;
    font-weight: 700;

    border-radius: 10px;
    border-style: solid;
}
.registerButton:hover{
    cursor: pointer;
    transform: scale(1.05)
}
.iconContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* width: 35px;
    height: 200px; */
    margin-top: 250px;
}
.icon:hover {
    cursor: pointer;
    transform: scale(1.1)
}
