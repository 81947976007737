:root {
    --white: #FFFFFF;
    --navy: #22577C;
    --lightBlue: #C7DDED;
    --background: #E5E5E5;
    --yellow: #f9c81999;
}
.team{
    display: flex;
    flex-direction: column;

    width: 100%;
    height: auto;

    background-color: var(--background);

    padding-bottom: 50px;
}
.teamHeader{
    margin-top: 50px;
}
.cardContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;

    width: 90%;
    height: auto;

    margin-left: 5%;
    /* margin-right: 5%; */
    margin-top: 2%;
}
.officerCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 350px;
    height: 575px;

}
.officerImage{
    border-radius: 100%;
    width: 300px;
    height: 300px;

    margin-bottom: 10px;

}
.officerName{
    font-weight: 700;
    font-size: 30px;
    line-height: 45px;

}
.officerPosition{
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    margin-bottom: 15px;

    color: var(--navy);
}

.officerPosition:hover{
    cursor: pointer;
}
.officerBlurb{
    width: 300px;
    height: 100px;

    font-weight: 500;
    font-size: 17px;
    line-height: 25.5px;

    text-align: center;

    margin-bottom: 20%;
}
.officerEmail{
    font-weight: 500;
    font-size: 15px;
    line-height: 22.5px;

    color: var(--navy);
}
.officerEmail:hover{
    cursor: pointer;
}
