:root {
    --white: #FFFFFF;
    --navy: #22577C;
    --lightBlue: #C7DDED;
    --background: #E5E5E5;
    --yellow: #f9c81999;
}
.about{
    display: flex;
    flex-direction: column;

    width: 100%;
    /* height: 1100px; */
    height: auto;

    background-color: var(--background);

    /* margin-top: 125px; */
}
.aboutHeader{
    margin-top: 50px;
    margin-bottom: 20px;
}
.aboutSection{
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: space-evenly;

    width: 100%;
    height: 45%;

    /* margin-left: 1%; */
    margin-right: 5%;
    margin-top: 2%;
}

.aboutBlurb{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 40%;
    /* height: 100%; */

}
.slideShowContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 20px;

    /* width: 40%; */
    height: auto;

    position: static;

    /* z-index: ; */

}
.slideShow{
    width: 100%;
    height: 100%;

    margin-top: 2%;

}

.aboutTitle{
    font-size: 50px;
    font-weight: 700;
    line-height: 75px;

    color: var(--navy)
}

.aboutText{
    display: inline;

    font-weight: 600;
    font-size: 30px;
    line-height: 45px;

    margin-top: 25px;
}
