:root {
    --white: #FFFFFF;
    --navy: #22577C;
    --lightBlue: #C7DDED;
    --background: #E5E5E5;
    --yellow: #f9c81999;
}

.contact{
    width: 100%;
    /* height: 550px; */

    padding-top: 50px;

    background-color: var(--background);
}

.contactWaveContainer{
    position: relative;
    width: 100%;

}
.contactWave{
    position: absolute;
}
.contactContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;

    position: absolute;
    width: 100%;
    /* height: 500px; */

    margin-top: 7.5%;
}
.listservSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 50%;
    min-width: 350px;
}
.listservItems{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 30%;
}
.listservTitle{
    color: var(--navy);

    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
}
.contactSection{
    width: 50%;
    min-width: 300px;
}
.contactForm{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 75%;

    margin-left: 5%;

    height: 90%; 
}
.contactHeader{
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    color: var(--navy);

}
.contactTitle{
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    color: var(--navy);

    margin-top: 2.5%;
}

input{
    /* SHOULD BE CHANGED INLINE IN CONTACT FORM */
    height: 50px;

    border-color: transparent;
    border-radius: 10px;
}
input:focus{
    outline: none;
}
textarea{
    height: 30%;

    resize: none;

    border-color: transparent;
    border-radius: 10px;
}
textarea:focus{
    outline: none;
}
.contactSubmitButton{
    width: 40%;
    height: 55px;
    background-color: var(--navy);
    color: white;

    border-radius: 10px;
    border-style: solid;
    border-color: var(--navy);

    text-align: center;
    font-size: 17.5px;
    font-weight: 700;
    /* line-height: 38px; */

    margin-top: 5%;
}
.contactSubmitButton:hover{
    cursor: pointer;
    transform: scale(1.05);
}