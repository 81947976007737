:root {
    --white: #FFFFFF;
    --navy: #22577C;
    --lightBlue: #C7DDED;
    --background: #E5E5E5;
    --yellow: #f9c81999;
}

.events{
    width: 100%;
    /* height: 1000px; */
    height: auto;

    background-color: var(--background);
}
.eventsHeader{
    padding-top: 45px;
    margin-bottom: 50px;
}

.timelineContainer{
    position: relative;

    width: 100%;

}
.timeline{
    position: absolute;
    /* background-color: green; */

}
.eventBlock{
    display: flex;
    flex-direction: column;

    width: 225px;
    height: auto;
    /* height: 300px; */

    position: absolute;

}
.eventPoint{
    border-radius: 100%;
    background-color: var(--navy);
    width: 10px;
    height: 10px;
}
.eventTitle{
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;

    height: auto;

    margin-bottom: 10px;
}
.eventTimeLocation{
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
}
.eventBlurb{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 10px;


}
.mobileContainer{
    display: flex;
    flex-direction: column;

    height: auto;
}