:root {
    --white: #FFFFFF;
    --navy: #22577C;
    --lightBlue: #C7DDED;
    --background: #E5E5E5;
    --yellow: #f9c81999;
}

.home{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 850px;/* need this here*/

    background-color: var(--background);
}

.waveContainer{
    position: relative;

    margin-bottom: 5%;
    width: 100%;
    height: 35%;
}

.wave{
    position: absolute;
}

.homeBlurb {
    font-weight: 500;
    /* font-size: 35px; */

    margin-top: 10px;
    margin-left: 5%;

    line-height: 52.5px;

    width: 43%;

}
.bottomHome{
    /* display: flex; */
    /* flex-direction: row; */
    /* justify-content: space-evenly; */

    width: 90%;
    height: auto;

    /* height: 300px; */

    margin-left: 5%;
    /* margin-bottom: 5%; */


}

.buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    width: 40%;
    min-width: 300px;
    height: 40px;

    /* margin-left: 5%; */



}
.homePic{
    max-width: 50%;
    height: auto;

    position: absolute;

    margin-top: 30px;
    margin-left: 45%;
}
.firstHomeButton{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 30%;
    min-width: 100px;
    height: 45px;
    /* padding-right: 7.5%;
    padding-left: 7.5%; */


    background-color: var(--navy);
    color: white;

    text-align: center;
    font-weight: 700;

    border-radius: 10px;
    border-style: solid;
    border-color: var(--navy);
}
.firstHomeButton:hover{
    cursor: pointer;
    transform: scale(1.05);
}
.secondHomeButton{
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 30%;
    min-width: 100px;
    height: 45px;
    /*padding-right: 7.5%;
    padding-left: 7.5%; */

    background-color: white;
    color: var(--navy);

    text-align: center;
    font-weight: 700;

    border-radius: 10px;
    border-style: solid;
    border-color: var(--navy);
}
.secondHomeButton:hover{
    cursor: pointer;
    transform: scale(1.05);
}