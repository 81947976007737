:root {
    --white: #FFFFFF;
    --navy: #22577C;
    --lightBlue: #C7DDED;
    --background: #E5E5E5;
    --yellow: #f9c81999;
}
.join{
    width: 100%;

    background-color: var(--background);

    padding-bottom: 25px;
}
.joinHeader{
    padding-top: 50px;
}
.programContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    justify-content: center;

    margin-top: 3%;
    margin-left: 5%;
    width: 90%;
}
.program{
    display: flex;
    flex-direction: column;
    /* ADJUST WHEN IMAGE IS PUT IN */
    justify-content: space-evenly; 
    align-items: center;
    max-width: 400px;
    height: 650px;

    padding-right: 5%;
    padding-left: 5%;

    /* background-color: red; */

    /* border-width: black;
    border-style: solid; */
}
.programImage{
    width: 100%;
    height: 250px;
}
.programBlurb{
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 25.5px;
}